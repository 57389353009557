const config = {
  apiKey: "AIzaSyBjuHs4e2XCG6U9QPZ6tEnIC2A6rVLSscI",
  authDomain: "hrsh-ecommerce.firebaseapp.com",
  databaseURL: "https://hrsh-ecommerce-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hrsh-ecommerce",
  storageBucket: "hrsh-ecommerce.appspot.com",
  messagingSenderId: "544598507221",
  appId: "1:544598507221:web:95fd280cb1340d1e636c5c",
  measurementId: "G-JFGPYQZCZR"
};

export default config